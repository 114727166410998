import jQuery from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';
//--------------------------------------------------
// Carousel module

jQuery(function ($) {
  if ($('.mod-carousel') && jQuery().slick) {
    $('.mod-carousel').each(function () {
      //--------------------------------------------------
      // Convert to rems

      function rem(value) {
        var rem = value / 16;
        return rem;
      }

      //--------------------------------------------------
      // Options

      var carousel = $(this);
      var previous =
        '<button class="slick-prev"><span class="icon"><svg><use xlink:href="/public/svg/svg/global.svg#chevron-left" /></svg></span><span class="off-screen">View previous slide</span></button>';

      var next =
        '<button class="slick-next"><span class="icon"><svg><use xlink:href="/public/svg/svg/global.svg#chevron-right" /></svg></span><span class="off-screen">View next slide</span></button>';

      // breakpoint display carousel - if not defined, uses the previous breakpoints settings
      // data-x="yes"
      var small = $(this).data('small'); //yes or no
      var medium = $(this).data('medium'); //yes or no
      var large = $(this).data('large'); //yes or no

      // breakpoints - in pixels to make things easier to manage in the HTML, will be converted to REM's later
      // data-x-break="600"
      var smallBreak = $(this).data('small-break'); // max-width
      var mediumBreak = $(this).data('medium-break'); //max-width
      var largeBreak = $(this).data('large-break'); //min-width

      // breakpoint options - define as an array;
      // data-x-options='{"slidesToShow": 1}'
      var smallOptions = $(this).data('small-options');
      var mediumOptions = $(this).data('medium-options');
      var largeOptions = $(this).data('large-options');

      // default options - if not using breakpoints
      var autoplay = $(this).data('autoplay');
      var dots = $(this).data('dots');
      if ((dots == null) | (dots == 'false')) {
        dots = false;
      } else {
        dots = true;
      }
      var slidesToShow = dataDetect('slides-to-show');
      var slidesToScroll = dataDetect('slides-to-scroll');
      var rows = dataDetect('rows');
      var slidesPerRow = dataDetect('slides-per-row');

      function dataDetect(option) {
        if (typeof $(carousel).data('' + option + '') == 'undefined') {
          var dataVariable = 1;
        } else {
          var dataVariable = $(carousel).data('' + option + '');
        }
        return dataVariable;
      }

      //--------------------------------------------------
      // Carousel

      function modCarousel() {
        if (!carousel.hasClass('slick-initialized')) {
          carousel.slick({
            prevArrow: previous,
            nextArrow: next,
            dots: dots,
            infinite: true,
            speed: 1200,
            adaptiveHeight: true,
            autoplaySpeed: 5000,
            autoplay: autoplay,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToScroll,
            rows: rows,
            slidesPerRow: slidesPerRow,
          });
        }
      }

      function modCarouselRemove() {
        if (carousel.hasClass('slick-initialized')) {
          carousel.slick('unslick');
        }
      }

      //--------------------------------------------------
      // Load the carousel if no sizes are defined

      if (!small || !medium || !large) {
        modCarousel();
      }

      //--------------------------------------------------
      // Carousel for small viewports

      if (small) {
        enquire.register('screen and (max-width: ' + rem(smallBreak) + 'rem)', {
          match: function () {
            if (small == 'yes') {
              modCarousel();
              carousel.slick('slickSetOption', smallOptions, true);
              carousel.slick('refresh');
            } else {
              modCarouselRemove();
            }
          },
        });
      } //small

      //--------------------------------------------------
      // Carousel for medium viewports

      if (medium) {
        //--------------------------------------------------
        // If desktop width is defined, we need to define a
        // max width for the medium size

        if (smallBreak) {
          var mediumQuery =
            'screen and (min-width: ' +
            rem(smallBreak) +
            'rem) and (max-width: ' +
            rem(mediumBreak) +
            'rem)';
        } else {
          var mediumQuery =
            'screen and (max-width: ' + rem(mediumBreak) + 'rem)';
        }

        //--------------------------------------------------
        // Check if there are updated options

        enquire.register(mediumQuery, {
          match: function () {
            if (medium == 'yes') {
              modCarousel();
              carousel.slick('slickSetOption', mediumOptions, true);
              carousel.slick('refresh');
            } else {
              modCarouselRemove();
            }
          },
        });
      } //medium

      //--------------------------------------------------
      // Carousel for large viewports

      if (large) {
        enquire.register('screen and (min-width: ' + rem(largeBreak) + 'rem)', {
          match: function () {
            if (large == 'yes') {
              modCarousel();
              carousel.slick('slickSetOption', largeOptions, true);
              carousel.slick('refresh');
            } else {
              modCarouselRemove();
            }
          },
        });
      } //large
    }); //.each()
  } //.if()
}); //.ready()
